export default function Footer() {
	return (
		<div
			className="container normal"
			style={{ paddingTop: '20px', paddingBottom: '20px', fontSize: '0.9em' }}
		>
			<div className="d-flex justify-content-between flex-wrap" style={{ gap: '10px' }}>
				<div>© Julija Hauser Homöopathiexport</div>
				<div>
					<a href="">Impressum</a>
				</div>
				<div>
					<a href="">Datenschutzerklärung</a>
				</div>
			</div>
		</div>
	)
}
