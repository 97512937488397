interface Props {
	numberOfRows: number
}

export default function Calendar(props: Props) {
	const array: undefined[] = []

	for (let i = 0; i < props.numberOfRows; i++) {
		array.push(undefined)
	}
	if (!array.length) array.push(undefined)

	const gap: string = '8px'
	const height: string = '25px'
	const width: string = '25px'

	function renderDay(withLowerOpacity?: boolean): JSX.Element {
		return (
			<div
				style={{
					backgroundColor: 'white',
					height: height,
					width: width,
					opacity: withLowerOpacity ? 0.6 : 1,
				}}
			></div>
		)
	}

	return (
		<div
			style={{
				display: 'flex',
				gap: gap,
				flexDirection: 'column',
			}}
		>
			<div style={{ backgroundColor: 'white', height: height }}></div>
			{array.map((_e: undefined, i: number) => {
				return (
					<div
						key={i}
						style={{
							display: 'flex',
							flexDirection: 'row',
							gap: gap,
						}}
					>
						{renderDay()}
						{renderDay()}
						{renderDay()}
						{renderDay()}
						{renderDay()}
						{renderDay(true)}
						{renderDay(true)}
					</div>
				)
			})}
		</div>
	)
}
