import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Routes, Route, Navigate } from 'react-router-dom'

import './styles/globals.scss'
import Index from './pages/Index'
import NotFound from './components/NotFound'
import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
	<BrowserRouter>
		<Routes>
			{/*<Route path="/" element={<Navigate replace to="/index" />} /> */}
			<Route path="/" element={<Index />} />
			<Route path="/*" element={<NotFound />} />
		</Routes>
	</BrowserRouter>
)

reportWebVitals()
