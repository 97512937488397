import CalendarComponent from 'components/Calendar/Calendar'

import Button from 'components/Button/Button'
export default function Calendar() {
	return (
		<div className="container special">
			<h1>Termin vereinbaren</h1>
			<p>
				Möchten Sie gerne einen Termin vereinbaren? Sie erreichen mich mit dem folgenden
				Kontaktformular oder reservieren Sie sich gleich direkt im Kalender einen passenden
				Termin.
			</p>
			<div className="row">
				<div className="col-8">
					<p className="bold">Kontaktformular</p>
					<div className="d-flex flex-column" style={{ gap: '10px' }}>
						<input type="text" className="form-control" placeholder={'Vorname, Name'} />
						<input type="email" className="form-control" placeholder={'E-Mail'} />
						<input type="text" className="form-control" placeholder={'Telefon'} />
						<textarea rows={4} className="form-control" placeholder={'Mitteilung'} />

						<Button label="Senden" />
					</div>
				</div>
				<div className="col-4">
					<p className="bold">Direktbuchung</p>
					<CalendarComponent numberOfRows={4} />
					<div style={{ paddingTop: '15px' }} />
					<Button label="Zum Kalender" megaBlock={true} />
					<br />
					<p>Termine am Sa und So nach Vereinbarung</p>
				</div>
			</div>
		</div>
	)
}
