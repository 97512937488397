export default function Contact() {
	return (
		<div className="background-color-base">
			<div className="container normal" style={{ paddingBottom: '20px' }}>
				<h1>Kontakt</h1>
			</div>
			<div className="container d-flex">
				<div>
					<img src="img/map_zuerich.jpg" className="img-fluid zoom" />
				</div>
				<div>
					<img src="img/map_huenenberg.jpg" className="img-fluid zoom" />
				</div>
			</div>
			<div className="container normal">
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						flexWrap: 'wrap',
						gap: '40px',
					}}
				>
					<div>
						Homöopathie Praxis
						<br />
						Julija Hauser dipl. Homöopathin SHI
						<br />
						julija.hauser@rj-homeopathy.ch
						<br />
						079 310 37 82
					</div>

					<div>
						<strong>Mi/Fr Nachmittag</strong>
						<br />
						Dofourstrasse 106
						<br />
						8008 Zürich
					</div>
					<div>
						<strong>Mo/Di/Do</strong>
						<br />
						Bösch 41
						<br />
						6331 Hünenberg
					</div>
				</div>
			</div>
		</div>
	)
}
